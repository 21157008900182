.video-tt{
	.video-wrap{
		position: absolute;
		z-index: 2;
		background: url(../assets/tv-static.gif) repeat center center;
	}
	.tiktok{
		position: absolute;
		z-index: 3;
	}
	.video-cover{
		opacity: 0;
		// transition: opacity 0.5s;
		&.selected{
			opacity: 0;
		}
	}
}