@import './styles/variables';

.App.c,
.App.narratives
{
	display: flex;
	flex-direction: column;
	.page-content{
		flex-grow: 1;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

}

.narratives,
.see{
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	> .tiktok{
		box-shadow: 0px 6px 10px 0px rgba(207, 252, 82, 0.40), 0px -6px 10px 0px rgba(207, 252, 82, 0.40);
	}
	.tiktok{
		overflow: hidden;
		a.video-cover{
			position: relative;
			width: 100%;
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}
		.video-player{
			height: 100vw;
			width: 100vw;
			.vjs-big-play-button,
			.vjs-control-bar{
				display: none;
			}
		}
	}
	.node-tiktok{
		> .tiktok{
			border-radius: $big-radius;
			@extend .bordered;
			.node{
				font-size: 35px;
				display: flex;
				justify-content: center;
				width: 100%;
				text-align: center;
				align-items: center;
			}
		}
	}
}

@media (min-width: $phone-breakpoint){
	.narratives,
	.see{
		.tiktok{
			a.video-cover{
			}
			.video-player{
				height: 100%;
				width: 100%;
			}
		}
	}
}