@import "./styles/variables";

.App.n{
	.page-content{
		justify-items: center;
		display: flex;
		flex-direction: column;
	}
	&.mobile{
		.arrow-keys{
			display: none;
		}
	}
	&.desktop{
		.arrow-keys{
			position: fixed;
			bottom: 10px;
			right: 10px;
		}
	}
}
.narratives{
	flex-grow: 1;
	.contribute{
		position: fixed;
		bottom: 0px;
		left: 50%;
		font-size: 11px;
		text-align: center;
		padding: 30px 10px;;
		margin-left: -48px;
		svg{
			width: 40%;
			height: 40%;
			display: inline-block;
			margin-bottom: 3px;
		}
	}
	.legend{
		> a {
			border-radius: 100%;
			background-color: black;
			padding: 2px;
		}
	}
	.tiktok{
		box-shadow: 0px 6px 10px 0px rgba(207, 252, 82, 0.40), 0px -6px 10px 0px rgba(207, 252, 82, 0.40);
	}
	&.center-menu{
		.future{
			display: none;
		}
	}
	.meta-text{
		height: 120px;
		top: -120px;
		display: flex;
		align-items: flex-end;
	}
	.video-js .vjs-big-play-button,
	.vjs-control-bar{
		display: none;
	}
	
}
.center-menu{
	> .popover{
		margin-left: 40px;
	}
}
.center-menu-content{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	> a {
		font-size: 20px;
		&.contribute{
			margin: 0px 5px;
			font-size: 35px;
		}

	}
}
.pop-content{
	outline: none;
	&.side-right{
		background-color: $gray-bg;
	}
}
.trip-viewer{
	.trip{
		max-height: 250px;
		overflow: auto;
		> div {
			display: flex;
			font-size: 12px;
			margin-bottom: 10px;
			align-items: center;
			img{ 
				flex-shrink: 0;
				margin-right: 10px;
				@extend .img-thumb;
				height: 60px;
				width: 60px;
			}
		}

	}
}