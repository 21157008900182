.open-menu{
	.nav-link{
		> span{
			display: none;
		}
		&.logout{
			text-align: right;
		}
		&.active{
			> span{
				display: inline;
			}
			&.logout{
				text-align: center;
			}
		}
	}

}