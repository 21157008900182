.videos{
	padding-top: 100px;
	background-color: #6e6c77;
	background-size: cover;
	.masonry-grid{
		padding: 30px 20px;
	}
	.video-player{
		position: relative;
		border-radius: 15px;
		overflow: hidden;
		box-shadow: rgba(123, 123, 123, 0.2) 0px 0px 0px 1px;
		// visibility: hidden;
		.btns{
			position: absolute;
			top: 5px;
			right: 5px;
			width: 33px;
			display: flex;
			flex-direction: column;
			padding: 0px 0px;
			background-color: rgba(0,0,0,0.8);
			.image-link{
				display: none;
			}
			> * {
				padding: 8px;
				width: 100%;
				border-radius: 0px;
				&:first-child{
					border-radius: 5px 5px 0px 0px;
				}
				&.icon{
					color: white;
				}
				&:last-child{
					border-radius: 0px 0px 5px 5px;
				}
				@media(hover:hover) {
					&:hover{
						background-color: #f3a3d6;
					}
				}
			}
			@media(hover:hover) {
				display: none;
			}
		}
		&:hover{
			.btns{
				display: flex;
			}
		}
	}
	.search{
		position: fixed;
		top: 20px;
		display: flex;
		flex-direction: column;
		left: 50%;
		width: 75%;
		@media(max-width: 768px){
			width: 55%;
		}
		transform: translateX(-50%);
		z-index: 101;
		.clear{
			position: absolute;
			bottom: 0px;
			right: 0px;
		}
		textarea{
			background-color: transparent;
			color: black;
			font-weight: bold;
			height: 140px;
			border-radius: 5px;
			text-align: center;
			padding-top: 60px;
			border: none;
			outline: none;
			@media(max-width: 768px){
				padding: 5px 3px 0px;
				font-size: 10px;
				height: 80px;
			}
		}
	}
	.load-more{
		text-align: center;
		color: #f3a3d6;
		text-shadow: 5px 5px 5px #1b4e02;
	}
	&.loading{
		background-image: url(./assets/underground.png);
		.load-more{
			font-weight: bold;
			padding: 50px;
			font-size: 30px;
		}
	}
	@media(max-width: 768px){
		padding-top: 60px;
	}
}
.video-clip{
	.vjs-control-bar{
		display: none;
	}
}
.video-player{
	.btns{
		border-radius: 5px;
		> * {
			padding: 8px 12px;
			border-radius: 5px;
			&.fork{
				transform: rotate(90deg);
				padding-top: 12px;
			}
			&.commit{
				font-size: 1.4em;
				padding-top: 10px;
			}

			@media(hover:hover) {
				&:hover{
					background-color: #f3a3d6;
				}
			}
		}
	}
}
.App.videos{
	.App-header{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		padding: 15px 30px 120px;
		background: url(./assets/videos-top.png) no-repeat center bottom;
		background-size: cover;
		@media(max-width: 768px){
			padding: 10px 15px 40px;
		}
	}
}