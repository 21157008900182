.clip-maker{
	.poster{
		display: none;
	}
	&.dragging{
		.poster{
			display: block;
		}
		.video-player{
			display: none;
		}
	}

}