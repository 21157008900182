.video-buttons{
	.btns{
		border-radius: 5px;
		> * {
			padding: 8px 12px;
			border-radius: 5px;
			&.svg{
				padding-top: 12px;
			}
			&.fork{
				transform: rotate(90deg);
				padding-top: 12px;
			}
			&.commit{
				font-size: 1.4em;
				padding-top: 10px;
			}

			@media(hover:hover) {
				&:hover{
					background-color: #f3a3d6;
				}
			}
		}
	}
	&.uploaded{
		.btns{
			.continue,
			.clip,
			.fork{
				display: none;
			}
		}
	}

}