@import "./styles/variables";

.quick-continue{
	&.simple{
		.field-group{
			&.meta-text{
				textarea{
					border: none;
					padding-left: 0px;
				}
			}
			&.advanced{
				display: none;
			}
		}
	}
	&.advanced{
		.show-advanced{
			display: block;
		}
	}
	.prompt-container{
		position: relative;
		.image-container{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 77px;
			border-radius: $small-radius;
			@extend .light-border;
			overflow: hidden;
		}
		.narration-phrase{
			margin-top: 0;
			margin-bottom: 0;
			padding: 0px;
			border: none;
			.top-plus,
			.delete,
			.narration-info,
			.search-clips,
			.bottom-plus{
				display: none;
			}
			.prompt-line{
				display: block;
				label{
					display: none;
				}
				padding-left: 28%;
				textarea{
					height: 77px;
				}
			}
			.camera-controls{
				flex-direction: row;
			}
			.speed-box{
				label{
					display: none;
				}
				a {
					border-radius: 100%;
					@extend .light-border;
					font-size: 13px;
					padding: 3px 9px 3px 7px;
					&.active{
						background-color: $pop;
					}
				}
			}
			.neg-prompt{
				margin-top: 15px;
				position: relative;
				label{
					@extend .light-border;
					@extend .small-label;
					position: absolute;
					top: -7px;
					left: 7px;
				}
				input{
					padding-top: 20px;
				}
			}
		}
	}

}
.modal.quick-continue{
	.modal-inner{
		width: 100%;
		@media (max-width: $phone-breakpoint) {
			height: 100vh;
		}
		max-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		> a {
			display: none;
		}
		.buttons{
			display: none;
		}
	}
}