
.narration{
	&.audio{
		.narration-info{
			display: none;
		}
		.speed-box,
		.strength,
		.camera-movement{
			display: inline !important;
		}
		.strength {
			input{
				padding-top: 4px;
				padding-bottom: 4px;
			}
		}
	}
	.start-with{
		a{
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid pink;
			border-radius: 15px;;
			padding: 40px 0 30px;
			font-size: 1.4rem;;
			svg{
				font-size: 3rem;
			}
			@media (hover: hover){
				&:hover{
					background-color: pink;
					color: #000;
				}
			}
		}
	}
	.narration-phrase{
		.delete{
			font-size: 14px;
		}
		.speed-box,
		.strength,
		.camera-movement{
			display: none;
		}
		&:first-child{
			.top-plus{
				display: none;
			}
		}
	}
	&.forked{
		.helper{
			display: none;
		}
	}
	.speed-box {
		//background-color: blue;
		a {
			color: #fff;
			//padding: 10px;
			//  display: inline-block;
			text-align: center;
			width: 30px;
			height: 30px;
			//border: 1px solid #fff;
			&.active{
				$bg: #777;
				background-color: $bg;
			}
			&:first-of-type{
				margin-left: 7px;
			}
		}
	}
}