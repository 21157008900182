
.App.weird {
	.page-content{
		width: 100%;
		background: url(./assets/console.png) top no-repeat;
		background-size: 100% auto;
	}
	.generate-video{
		width: 100%;
		position: relative;
		.select{
			position: absolute;
			top: -5.2vw;
			text-align: center;
			left: 50%;
			$w: 300px;
			border: 2px solid black;
			background-color: #fa1db5;
			border-radius: 10px;
			color: black;
			padding: 4px 10px 4px;;
			margin-left: -$w / 2;
			width: $w;
			z-index: 4;
			// width: 100%;
			@media(max-width: 768px) {
				top: 300px;
				// display: none;
			}
		}
		textarea{
		position: relative;
			display: block;
			background-color: black;
			width: 50%;
			height: 38vw;
			border-radius: 15px;
			margin: 13.5% auto 0;
			border: 2px solid #c44fc3; 
			z-index: 102;
		}
		.buttons{
			text-align: center;
			margin-top: 8vw;
			border-radius: 15px;
			// background-color: rgba(0, 0, 0, 0.8);
			width: 100%;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
			h2{
				font-size: 40px;
				line-height: 1.1;
				// crazy pink shadow
				text-shadow: 3px 3px 0px #c44fc3;
				color: yellow;

			}
		}
		.instructions{
			
		}
	}
}
