@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=Sarabun:wght@400;700&display=swap');

.App.berf{
	&.home{
		.App-header{
			position: fixed;
			top: 0px;
			left: 0px;
			z-index: 10;
			width: 100%;
		}
		.page-content{
			h1 {
				font-family: 'Croissant One', cursive;
				font-size: 100px;
				text-align: center;
				color: white;
				text-shadow: #41514b 4px 0px;
				line-height: 1;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 0 20px 40px 0;
        @media (max-width: 768px) {
          font-size: 80px;
          padding: 0 20px 70px 20px;
        }
			}
		}
    .cave-wrap{
      position: relative;
      width: 100%;
      height: 100vh;
      max-width: 100%;
      background-size: cover;
      > a.intro{
        position: absolute;
        top: 27%;
        left: 10%;
        z-index: 3;
        width: 37%;
        height: 25%;
        z-index: 3;
        display: block;
        // background-color: green;
      }
      .video-player{
        width: 100%;
      }
      video{
        position: absolute;
        width: 13vh;
        left: 50%;
        margin-top: 33vh;
      }
    }
    &.wide{
      .cave-wrap{
        video{
          width: 14vw;
          margin-left: -24vw;
          top: 50vh;
          margin-top: -17vw;
  
        }
      }
    }
    &.tall{
      .cave-wrap{
        video{
          margin-left: -24vh;
        }
      }
    }
    .cave{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      max-width: 100%;
      z-index: 2;
      background: url(./assets/berf-hp.png) no-repeat center center;
      height: 100vh;
      background-size: cover;
    }
	}
}