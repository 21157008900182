.auth-form{
	padding: 15px;;
	&.closed{
		right: 40px;
		top: 7px;
	}
	&.open{
		position: fixed;
		top: 0 !important;
		right: auto !important;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0,0,0,0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		.form-wrap{
			width: 90%;
			max-width: 400px;
			// height: 70vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			background-size: cover;
			border-radius: 15px;
			overflow: auto;
			border: 1px solid;
			padding: 15px;
			.form{
				padding: 10px;
				padding-top: 0;
				margin-top: 10px;
				background-color: rgba(0,0,0,0.5);
				border-radius: 15px;
			}
			h1{
				font-size: 2rem;
				font-weight: bold;
			}
		}
		&.signup{
			.form-wrap{
				//background: url(./assets/signup.png) center no-repeat;
				//background-size: cover;
				background-color: rgba(0,0,0,1);
			}
			.cols{
				background-color: rgba(0,0,0,0.7);
			}
		}
		&.login{
			.form-wrap{
				//background: url(./assets/login.png) center no-repeat;
				//background-size: cover;
				background-color: rgba(0,0,1);
				h1{
					small{
						font-size: 12px;
					}
				}
				.confirm,
				.benefits{
					display: none;
				}
			}
		}
	}
	.cols{
		width: 100%;
		max-width: 500px;
		margin: 15px auto;
		display: flex;
		justify-content: space-between;
		// background-color: rgba(0,0,0,0.7);
		border-radius: 15px;
		padding: 15px;
		@media (max-width: 500px){
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		> div {
			flex: 1;
		}
		.benefits{
			text-align: left;
			padding-right: 15px;
		}
	}
}