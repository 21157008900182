@import "./styles/variables";

.audio-chooser{
	a {
		border-radius: 8px;
		border: 0.5px solid $pop;
		aspect-ratio: 1/0.9;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wrap{
		&.btns{
			display: flex;
			.btns{
				width: 20%;
				margin-right: 20px;
				svg{
					font-size: 25px;
				}
				a{
					margin-bottom: 7px;
					&.check{
						background-color: $pop;
						color: black;
					}
					
				}
			}
			.thing-list,
			.main{
				flex-grow: 1;
			}
		}
	}
	.btn.generate{
		padding: 5px 10px;
		font-size: 12px;
	}
	.btn.delete{
		padding: 0px 10px;
	}
	.choices{
		display: flex;
		justify-content: center;
		padding: 5px 0 0;
		> a {
			margin: 0px 10px;
			font-size: 11px;
			width: 25%;
			svg{
				font-size: 22px;
			}
		}
	}

}