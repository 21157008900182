
.thing-list{
	ul{
		height: 150px;
		overflow: auto;
	}
}

.App .react-select__indicators{
	display: none;

}