@import "./variables";

.App.tv{
	header.App-header{
		.logo{
			display: none;
		}
	}
	.input{
		border-radius: 8px;
		border: 0.2px solid darken($pop, 35%);
		padding: 5px 7px;
		background: transparent;
		color: $pop;
		font-size: 0.75rem;
		line-height: 1.3;
		letter-spacing: -0.5px;
		&:focus{
			outline: none;
			border-color: $pop;
			box-shadow: none;
			color: $pop;
		}
	}
	input {
		color: $pop;
	}
	select.input{
		border-radius: $big-radius;
		padding: 3px 20px 3px 7px;
		background: url(../assets/icons/down-carrot.svg) no-repeat right 5px top 50%;
		background-size: 10px;
	}
	label{
		font-size: $font-size-tiny;
	}
	&.loggedout{
		.buy-berfs{
			display: none;
		}
	}
}
.field-group{
	position: relative;
	border-radius: $big-radius;
	border: 1px solid $pop;
	padding: 16px 10px 15px;
	> label{
		position: absolute;
		top: -10px;
		left: 10px;
		@extend .small-label;
	}
	&.sm{
		font-size: 13px;
	}

}
