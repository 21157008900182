@import "./styles/variables";

.App{
	.braintree-form__field {
		input {
			color: $pop !important;
		}
	}
	input {
		color: $pop !important;
	}
	.braintree-sheet__header {
		border-bottom: 1px solid $pop;
		background-color: $black;
	}
	.braintree-sheet__text {
		color: $pop;
		font-family: "Azeret Mono", monospace;
	}
	#braintree-hosted-field-postalCode input {
		color: #fff !important;
	}
	#braintree-drop-in-div {
		.braintree-sheet__content--form .braintree-form__field-group .braintree-form__label{
			color: $pop;
			font-family: "Azeret Mono", monospace;
		}
		.braintree-sheet__content.braintree-sheet__content--form {
			background-color: black;
		}
		.braintree-sheet {
			//background-color: blue;
			border: 1px solid $pop;
		}
		
		.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
			border: 1px solid $pop;
		}
		iframe#braintree-hosted-field-number {
			background-color: transparent;
		}
	}
	
	.braintree-show-card .braintree-card, .braintree-show-methods .braintree-methods, .braintree-show-delete-confirmation .braintree-delete-confirmation, .braintree-show-options .braintree-options, .braintree-show-paypal .braintree-paypal, .braintree-show-paypalCredit .braintree-paypalCredit, .braintree-show-applePay .braintree-applePay, .braintree-show-googlePay .braintree-googlePay, .braintree-show-venmo .braintree-venmo,
	.braintree-form__hosted-field iframe input[type="number"]
	{
		// background-color: black;
		// color: white;
	}
}