@import "styles/variables";

.my-stuff{
	position: fixed;
	bottom: 0px;
	left: 0px;
	z-index: 10;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	a.open{
		font-size: 1.5em;
		position: absolute;
		top: -40px;
		left: 20px;
	}
	&.open{
		border-top: 1px solid $pop;
		// overflow-y: auto;
	}
	.content{
		padding: 10px 20px 20px;
		max-height: 35vh;
		overflow-y: auto;
		.story{
			margin-bottom: 10px;
		}
	}
}