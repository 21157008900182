$black: #000;
$white: #fff;
$green: #84FA4E;
$yellow: #CFFC52;
$blue: #57C0FA;
$purple: #EA3ABD;
$red: #EB364D;
$gray-dark: rgba(32, 30, 30, 0.85);
$gray-bg: #201e1e;

$bg: $black;
$pop: $green;
$pop-med: darken($pop, 35%);

$big-radius: 17px;
$small-radius: 12px;

$font-size-tiny: 11px;

$phone-breakpoint: 600px;

.small-label{
	padding: 0 5px;
	border: 1px solid $pop;
	border-radius: $small-radius;
	background: $bg;
	display: inline-block;
	padding: 2px 7px;
	color: $pop;
	font-size: $font-size-tiny;
}
.bordered{
	border: 1px solid $pop;
}
.light-border{
	border: 0.2px solid darken($pop, 35%);
}
.text-shadow{
	text-shadow: 0px 0px 8px $pop;
}
.img-thumb{
	border-radius: $small-radius;
	@extend .light-border;
	overflow: hidden;
}
.text-red{
	color: $red;
}