
ul.tags{
	list-style: none;
	padding: 0;
	li {
		display: inline-block;
		padding: 0px 5px 3px;
		margin: 0px 10px 10px 0px;
		border-radius: 4px;
		border: 1px solid #ccc;
		.tag-name{
			display: inline-block;
			padding: 0px 15px;
		}
		&.mine{
			background-color: pink;
			color: black;
			a{
				color: black;
			}
		}
	}
	
}