@import "./styles/tv.scss";
@import "./styles/variables";
.App {
  background-color: black;
  color: $pop;
  min-height: 100vh;
  font-family: 'Azeret Mono', monospace;
  line-height: normal;
  letter-spacing: -0.04em;
  .App-header{
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
    display: flex;
    padding: 10px;
    padding-bottom: 0;
  }
  h1.logo{
    background: url(./assets/logo-4.png) center center no-repeat;
    display: flex;
    height: 70px;
    width: 70px;
    background-size: auto 100%;
    font-size: 0.8rem;
    a{
      color: transparent;
    }
    > * {
      flex: 1;
    }
  }
  .search-icon{
    position: fixed;
    right: 12px;
    top: 16px;
    z-index: 10;
    /* font-weight: bold; */
    font-size: 22px;
    background: black;
    border-radius: 100px;
    padding: 5px;
  }
  .buy-berfs{
    color: black;
    .fire-icon{
      display: none;
    }
  }
  &.no-fire,
  &.fire{
    .buy-berfs{
      color: $pop;
      .fire-icon{
        display: inline-block;
      }
    }
  }
  &.desktop{
    a.refresh{
      display: none;
    }
  }
  h1{
    font-size: 3rem;
  }
  h2{
    font-size: 2rem;
  }
  a {
    cursor: pointer;
    // text-decoration: underline;
  }
  /* Style the unordered list */
  ul {
    list-style-type: disc; /* Use disc bullets (default) */
    padding-left: 20px;    /* Add some left padding to indent the list */
  }

  /* Style the list items */
  li {
    margin-bottom: 5px;    /* Add space between list items */
  }

  .btn{
    text-decoration: none;
    color: white;
  }
  .btn {
    display: inline-block;
   // --tw-gradient-from: grey;
   // --tw-gradient-to: black;
   // background-image: linear-gradient(to top right, var(--tw-gradient-from), var(--tw-gradient-to));
   background-color: $gray-dark;
    
    &:hover {
      --tw-gradient-from: #3b82f6;
      --tw-gradient-to: #34d399;
      --tw-shadow: 0 25px 50px -12px rgb(213, 255, 225);
      color: rgb(229, 229, 229);
    }
    
    font-size: 1rem;
    // font-weight: 700;
    padding: 5px 15px;
    border-radius: 9999px;
   // --tw-shadow: 0 25px 50px -12px rgb(103, 255, 146);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    //transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 700ms;
    
    &:hover {
      transition-duration: 700ms;
      border-color: rgba(139, 92, 246, var(--tw-border-opacity));
    }
    &:disabled{
      filter: grayscale(100%);
      cursor: not-allowed;
      opacity: 0.5;
    }
    
    border-width: 0.5px;
    --tw-border-opacity: 1;
    // border-color: rgba(139, 92, 246, var(--tw-border-opacity));
    border-color: $pop;
    color: $pop;
    &.cancel{
      border-color: $red;
      color: $red;
    }
    svg{
      display: inline-block;
      font-size: 1.1em;
    }
    &.btn-lg{
      font-size: 1.5rem;
      padding: 10px 20px;

    }
  }
  input {
    color: $pop;
  }
  input[type='text'],
  input[type='number']{
    background-color: transparent !important;
    color: $pop;
  }
  .hide-advanced{
    display: block;
    &.inline{
      display: inline;
    }
  }
  .show-advanced{
    display: none;
  }
  &.advanced{
    .show-advanced{
      display: block;
    }
    .show-advanced.inline{
      display: inline;
    }
    .show-advanced.inline-block{
      display: inline-block;
    }
    .hide-advanced.inline,
    .hide-advanced{
      display: none;
    }
  }
  > .modal{
    z-index: 1000;
    .modal-inner{
      max-height: 80vh;
      border: 1px solid $pop;
    }
  }
  nav.main{
    > * {
      padding: 10px 20px;
      &.active{
        color: palevioletred;
        text-decoration: underline;
      }
    }
  }
  &.tt{
    .page-content{
      overflow: hidden;
      width: 100vw;
      position: relative;
      flex-direction: column;
      display: flex;
    }
  }
  &.narration,
  &.v,
  &.audio{
    .page-content{
      width: 100%;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .open-menu{
    z-index: 1000;
    right: 25px;
    top: 50px;
    .title{
      padding-left: 15px;
      // text-align: center;
      // display: inline-block;
      // width: 100%;
      // font-weight: bold;
      // text-transform: uppercase;
    }
  }

  &.n,
  &.tt,
  &.aiaiaiaiai{
    display: flex;
    flex-direction: column;
    position: relative;
    .page-content{
      // width: 100%;
      // max-width: 700px;
      flex: 1;
    }
    &.home{
      .App-header{
        position: absolute;
        left: 50%;
        margin-left: -18vh;
        padding: 1.3vh;
        top: 8px;
        width: 37.5vh;
        z-index: 100;
        h1.logo{
          width: 6vh;
          height: 6vh;
        }
        .auth-form{
          right: 3vh;
          top: 3vh;
          padding: 0;
        }
      }
      .open-menu{
        width: 200px;
        margin-left: -100px;
        left: 50%;
        top: 10vh;
      }
    }
    &.weird{
      .App-header{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 100;
        width: 100%;
      }

    }
    &.auth{
      .App-header{
        z-indeX: 1000;
      }
    }
    .homepage{
      .video-player{
        button,
        a{
          display: none;
        }
      }
      .cave-wrap{
        position: relative;
        width: 100%;;
        height: 80vh;
        max-width: 100%;
        > a.intro{
          position: absolute;
          top: 20%;
          left: 25%;
          z-index: 3;
          width: 50%;
          height: 35%;
          z-index: 3;
          display: block;
          // background-color: green;
        }
        a.weird{
          position: absolute;
          top: 50vh;
          left: 50%;
          margin-left: -108px;
          z-index: 3;
          display: block;
        }
        .video-player{
          width: 100%;
        }
        video{
          position: absolute;
          width: 20vh;
          margin-left: -10vh;
          left: 50%;
          margin-top: 23vh;
        }
      }
      .cave{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        max-width: 100%;
        z-index: 2;
        background: url(./assets/jukebox.png) center no-repeat;
        height: 80vh;
        background-size: auto 111%;
      }
    }
    .checkout{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .animated-button{
      border-radius: 20px;
      // border: 1px solid white;
      box-shadow: 5px 5px 5px rgba(255, 255, 246, 0.4);
    }
    .rabbit{
      $hw: 12vh;
      width: $hw;
      height: $hw;
      background: url(./assets/rabbit-0.png) center no-repeat;
      background-size: 250%;
      position: absolute;
      bottom: 40px;
      margin-left: -$hw / 2;
      left: 50%;
      z-index: 3;
      border-radius: 100%;
      border: 5px solid #fb0dc8;
      @media(max-width: 768px){
        bottom: 20px;
      }
      &.running{
        // background-size: 250%;
      }
      &.frame-1{
        // background-size: 150%;
        background-image: url(./assets/rabbit-1.png);
      }
      &.frame-2{
        // background-size: contain;
        background-image: url(./assets/rabbit-2.png);
      }
    }

  }
  &.loggedout{
    .search-icon{
      display: none;
    }
    .user-only{
      display: none;
    }
  }
}
.error-page{
  background: url(./assets/404.png) center no-repeat;
  background-size: cover;
  color: white
}


.sticky {
  z-index: 999;
}