.uploader{
	border: 2px dotted rgb(236, 72, 153); 
	padding: 30px 15px;
	color: rgb(236, 72, 153);
	display: flex;
	flex-direction: column;
	button{
		display: block;
		height: 50px;
	}
	p{
		text-align: center !important;
	}
	&.uploading,
	&.active{
		color: black;
		border-color: purple;
		background-color: rgb(236, 72, 153);
	}
	&.uploading{
		color: white;
		border-color: white;
	}
}